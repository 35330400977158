import { useState, useEffect } from 'react';
import rootApi from '@utils/baseAPI/WrapperApi';

const useFetchWithRetry = (productId, maxRetries = 3, retryDelay = 20000) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (!productId) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await rootApi.get('/crawls/step-price', {
          params: { product_id: productId, page: 1, limit: 5 }
        });

        if (response.data?.data?.length > 0) {
          setData(response);
          setLoading(false);
          setError(null); // Xóa error nếu có
        } else {
          throw new Error('Không có dữ liệu');
        }
      } catch (err) {
        console.error('Lỗi API:', err);
        setError(err);

        // Nếu chưa đạt max retry, tiếp tục thử lại sau 20s
        if (retryCount < maxRetries) {
          setTimeout(() => setRetryCount((prev) => prev + 1), retryDelay);
        } else {
          setLoading(false); // Dừng loading khi hết retry
        }
      }
    };

    fetchData();
  }, [productId, retryCount]); // Gọi lại khi `retryCount` thay đổi

  return { data, loading, error, retryCount };
};

export default useFetchWithRetry;
