import React, { useEffect, useState } from 'react';
import { Button, Card, message, Space, Typography } from 'antd';
import { HeartOutlined, ClockCircleOutlined, HeartFilled } from '@ant-design/icons';
import { formatCurrency, getTimeDifferenceV2 } from '@utils/utils';
import { useNavigate } from 'react-router-dom';
import { loadFromLocalStorage } from '@databases/localStorage';
import { constantExchangeRate } from '@contants/index';
import { calculateTimeLeft } from '@contants/formatDayTime';
import {
  apiRemoveFavoriteProduct,
  apiSetFavoriteProduct
} from '@services/MangeCustomer/CustomerServices';

const { Title, Text } = Typography;

export const ProductItem = ({
  imgUrl,
  title,
  targetUrl,
  time,
  priceYen,
  priceDong,
  endDate,
  auctionId,
  isSearchEngine,
  isFavorite: initialIsFavorite,
  productId,
  onRemoveFavorite
}) => {
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState(initialIsFavorite);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, endDate]);

  const { days, hours, minutes, seconds } = timeLeft;

  const handleClick = () => {
    navigate(`/account/auction/${auctionId}?fromSearch=true&isUrl=true`);
  };
  const exchangeRateJPY = JSON.parse(
    loadFromLocalStorage(constantExchangeRate.EXCHANGE_RATE)
  )?.bank_sell;

  const handleToggleFavorite = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const newFavoriteStatus = !favorite;
      const param = {
        product_id: productId
      };
      if (newFavoriteStatus) {
        await apiSetFavoriteProduct(param); // API thêm vào yêu thích
      } else {
        await apiRemoveFavoriteProduct(param); // API bỏ thích
        onRemoveFavorite(productId);
      }

      setFavorite(newFavoriteStatus);
      message.success(newFavoriteStatus ? 'Đã thêm vào yêu thích' : 'Đã bỏ yêu thích');
    } catch (error) {
      message.error('Lỗi khi cập nhật yêu thích');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card
      cover={
        <img
          alt={title}
          src={imgUrl}
          style={{
            height: '164px',
            width: '100%',
            objectFit: 'cover',
            cursor: 'pointer'
          }}
          onClick={handleClick}
        />
      }
      style={{
        height: '312px'
      }}
      bodyStyle={{
        padding: '12px'
      }}>
      <Space direction="vertical" size="8px" style={{ width: '100%' }}>
        {isSearchEngine && !seconds ? (
          <Space size="small" align="start" style={{ marginLeft: '0px', marginTop: '0px' }}>
            <ClockCircleOutlined style={{ fontSize: '16px', color: '#fff' }} />
          </Space>
        ) : seconds !== undefined ? (
          <Space size="small" align="start" style={{ marginLeft: '0px', marginTop: '0px' }}>
            <ClockCircleOutlined style={{ fontSize: '16px', color: '#626D7C' }} />
            <Text
              style={{
                fontSize: '14px'
              }}>{`${days}d - ${hours}h : ${minutes}' : ${seconds}s`}</Text>
          </Space>
        ) : (
          <Space size="small" align="start" style={{ marginLeft: '0px', marginTop: '0px' }}>
            <ClockCircleOutlined style={{ fontSize: '16px', color: '#626D7C' }} />
            <Text
              style={{
                fontSize: '14px',
                color: '#F82859',
                fontWeight: '500'
              }}>
              Đấu giá kết thúc
            </Text>
          </Space>
        )}
        <Title
          style={{
            fontSize: '14px',
            fontWeight: '500',
            cursor: 'pointer',
            marginBottom: '0px'
          }}
          onClick={handleClick}>
          {title}
        </Title>
        <Space size={'small'} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Title
              style={{
                fontSize: '16px',
                fontWeight: '600',
                color: '#F82859',
                marginBottom: '4px'
              }}>
              {`¥ ${formatCurrency(priceYen)}`}
            </Title>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#626D7C',
                marginBottom: '0px'
              }}>
              {`≈ ${formatCurrency(priceDong * exchangeRateJPY, 2)} đ`}
            </Text>
          </div>
          <Button
            type="text"
            icon={
              favorite ? (
                <HeartFilled style={{ color: '#F82859' }} />
              ) : (
                <HeartOutlined style={{ color: '#626D7C' }} />
              )
            }
            key="favorite"
            style={{ borderRadius: '36px' }}
            loading={loading}
            onClick={handleToggleFavorite}
          />
        </Space>
      </Space>
    </Card>
  );
};
