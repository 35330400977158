import React from 'react';

// Router
import Router from './routes';

// Component
import AuthProvider from '@contexts/Auth/AuthProvider';
import ScrollToTop from '@components/ScrollToTop';
import { SettingsProvider } from '@contexts/Setting/SettingsContext';
import Theme from '@theme/Theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'assets/css/override.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { MaintenanceProvider } from '@contexts/Auth/MaintainModeProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      cacheTime: 0
    }
  }
});

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        {/* <MaintenanceProvider> */}
        <ScrollToTop />
        <SettingsProvider>
          <Theme>
            <ReactNotifications />
            <Router />
          </Theme>
        </SettingsProvider>
        {/* </MaintenanceProvider> */}
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
