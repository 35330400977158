import { loadFromLocalStorage, saveToLocalStorage } from '@databases/localStorage';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { contantAuthentication, constantExchangeRate } from '@contants/index';
import { showError } from '@components/notification';

// Context.
import { AuthContext } from './Authcontext';

// Service.
import { signIn, resetPassword, Registered } from '@page/Auth/requests';
import { getMe } from '@utils/urls';
import { apiGetDetailCurrency } from '@services/ManageFee/FeeServices';
import { apiGetModeMaintenance } from '@services/MangeCustomer/CustomerServices';
import { checkUserRole } from '@utils/utils';

const AuthProvider = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = React.useState(false);
  const [user, setUser] = React.useState(
    loadFromLocalStorage(contantAuthentication.USER_DATA) || null
  );
  const [token, setToken] = React.useState(loadFromLocalStorage(contantAuthentication.DATA_AUTH));
  const fetchExchangeRate = async () => {
    try {
      const exchangeRate = await apiGetDetailCurrency({ currency_code: 'JPY' });
      saveToLocalStorage(
        constantExchangeRate.EXCHANGE_RATE,
        JSON.stringify(exchangeRate?.data?.data)
      );
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (isLogin) {
      const origin = location.state?.from?.pathname || '/customer/dashboard';
      navigate(origin);
    }
  }, [isLogin]);
  const handleLogin = async (username, password) => {
    try {
      // Gọi API đăng nhập
      const response = await signIn(username, password);

      if (![200, 201].includes(response?.status) || response?.data.status.code !== 'success') {
        showError(response?.data?.status?.message || 'Lỗi đăng nhập, vui lòng thử lại!');
        return;
      }

      // Lưu token vào localStorage
      const tokenUser = JSON.stringify(response?.data.data);
      setToken(tokenUser);
      saveToLocalStorage(contantAuthentication.DATA_AUTH, tokenUser);

      // Gọi API kiểm tra chế độ bảo trì
      const mode = await apiGetModeMaintenance();

      // Nếu không trong chế độ bảo trì, tiếp tục lấy thông tin user
      const userResponse = await getMe();
      console.log('🚀 ~ handleLogin ~ userResponse:', userResponse);

      const userRole = checkUserRole(userResponse?.data.data);

      if (mode?.data?.data === true && userRole === 'maintenance') {
        navigate('/maintain-mode'); // Chuyển hướng đến màn bảo trì nếu đang bật
        return;
      }
      if (userResponse?.status === 200) {
        saveToLocalStorage(contantAuthentication.USER_DATA, JSON.stringify(userResponse.data.data));

        setTimeout(() => {
          setIsLogin(true);

          // Kiểm tra role để điều hướng
          const userRoles = userResponse.data.data?.roles || [];
          const targetPath =
            userRoles.length === 1 && userRoles.includes('CUSTOMER')
              ? '/customer/dashboard'
              : '/customer/dashboard'; // Có thể cập nhật đường dẫn này nếu cần

          navigate(targetPath);
        });
      }
    } catch (error) {
      console.error('Lỗi đăng nhập:', error);
      showError('Đã xảy ra lỗi, vui lòng thử lại!');
    }
  };

  const handleLogout = async () => {
    // Call after Logout Success.
    // const response = await signOut();
    // if (response.status === 200) {
    setToken('');
    saveToLocalStorage(contantAuthentication.DATA_AUTH, '');
    saveToLocalStorage('searchHistory', []);
    setIsLogin(false);
    navigate('/account/signin');
    // }
  };

  const handleResetPassword = async () => {
    // Call after Logout Success.
    // const response = await resetPassword();
    // if (response.status === 200) {
    setToken('');
    saveToLocalStorage(contantAuthentication.DATA_AUTH, '');
    setIsLogin(false);

    navigate('/account/signin');
    // }
  };

  const handleRegister = async (values) => {
    console.log(values);
    // Call after Logout Success.
    // const response = await Registered();
    // if (response.status === 200) {
    setToken('');
    saveToLocalStorage(contantAuthentication.DATA_AUTH, '');
    setIsLogin(false);
    navigate('/account/signin');
    // }
  };
  const updateAvatar = (data) => {
    if (data) {
      const updatedUser = { ...data };
      setUser(updatedUser);
      saveToLocalStorage(contantAuthentication.USER_DATA, JSON.stringify(updatedUser));
    }
  };

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    resetPassword: handleResetPassword,
    register: handleRegister,
    fetchExchangeRate: fetchExchangeRate,
    updateAvatar: updateAvatar,
    user
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
