import React, { useState } from 'react';
import rootApi from '@utils/baseAPI/WrapperApi';
import { Button, Modal, Table, Typography, Spin } from 'antd';
import { useQuery } from 'react-query';
import './DialogsHistory.css';
import * as moment from 'moment';
import useFetchWithInterval from './common/FetchIntervalHistory';
import { formatPrice } from '@contants/formatPrice';

const columns = () => [
  {
    title: 'Người đấu giá',
    dataIndex: 'account',
    render: (_, d) => (
      <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
        {d?.account}
      </Typography>
    ),
    width: 220
  },
  {
    title: 'Thời gian đặt',
    dataIndex: 'time',
    render: (_, d) => (
      <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
        {moment(d?.bid_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm')}
      </Typography>
    ),
    width: 220
  },
  {
    title: 'Giá đặt',
    dataIndex: 'price',
    render: (_, d) => (
      <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
        {formatPrice(d?.bid_amount)}
      </Typography>
    ),
    width: 220
  }
];

const DialogsHistoryAuction = ({ onCancel, visible, data: product }) => {
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: stepPrice,
    loading,
    error,
    retryCount
  } = useFetchWithInterval(product?.detail?.auction_id, 3, 20000);
  const stepPriceData = stepPrice?.data?.data || [];
  const totalItems = stepPrice?.data?.total || 0;
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <Modal
      title="Lịch sử đấu giá sản phẩm"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="back"
          style={{
            height: '36px',
            backgroundColor: '#E8EBEF',
            color: '#192434',
            fontSize: '14px',
            fontWeight: '500',
            borderRadius: '4px'
          }}
          onClick={onCancel}>
          Đóng
        </Button>
      ]}
      width={720}
      style={{
        paddingLeft: '16px',
        paddingRight: '16px',
        cursor: 'pointer'
      }}>
      {loading ? (
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <Spin tip="Đang tải dữ liệu..." />
        </div>
      ) : error?.status?.code == 5000 && retryCount == 2 ? (
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <Typography style={{ color: 'red' }}>
            Hiện tại hệ thống đang bận. Vui lòng kiểm tra lịch sử đấu sau ít phút.
          </Typography>
        </div>
      ) : (
        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={stepPriceData}
          columns={columns()}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalItems,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} của ${total} mục`
          }}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          className="custom-table"
        />
      )}
    </Modal>
  );
};

export default DialogsHistoryAuction;
