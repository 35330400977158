import rootApi from '@utils/baseAPI/WrapperApi';
import { checkUserRole } from '@utils/utils';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';

const MaintenanceContext = createContext(false);

export const MaintenanceProvider = ({ children }) => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { data: mode } = useQuery(['get-mode-maintenance'], () => rootApi.get('/maintain-modes'), {
    onSuccess: (data) => {
      setIsMaintenance(data?.data?.data);
    }
  });
  const { data: user } = useQuery(['get-user-info'], () => rootApi.get('/users/me'));
  const userInfo = user?.data.data;
  const userRole = checkUserRole(userInfo);
  const isAdmin = userRole === 'allowed';

  useEffect(() => {
    if (!isAdmin && isMaintenance) {
      // Nếu user không phải admin và đang ở chế độ bảo trì, redirect đến /maintain-mode
      // if (location.pathname !== '/maintain-mode') {
      //   navigate('/maintain-mode', { replace: true });
      // }
    } else if (!isMaintenance) {
      // Nếu URL bị xoá hết (chỉ còn localhost:2013), chuyển về trang login
      // if (location.pathname === '/' || location.pathname === '') {
      //   navigate('/account/signin', { replace: true });
      // }
    }
  }, [isMaintenance, location.pathname, navigate, isAdmin]);

  return (
    <MaintenanceContext.Provider value={isMaintenance}>{children}</MaintenanceContext.Provider>
  );
};

export const useMaintenance = () => useContext(MaintenanceContext);
